import { ProjectSettings } from '@valiot/settings'
import Logo from 'src/components/Logo/Logo.js'
import packageJson from '../package.json'
import { getSettingsAlertsByRole } from './utils/functions'

const settings: ProjectSettings = {
  version: packageJson.version,
  title: 'Promeba - Valiot',
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
  },
  browserMonitoring: {
    enabled: import.meta.env.PROD,
    apiKey: import.meta.env.VITE_BROWSER_MONITORING_API_KEY,
  },
  customerLogo: {
    src: <Logo />,
  },
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'es',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  tenancy: {
    enabled: true,
  },
  sidebarDefault: 'expanded',
  home: 'monitoring/monitoring-process',
  alerts: {
    byRoles: getSettingsAlertsByRole(),
  },
  routes: [
    {
      path: 'assistant-sequence',
      text: 'Asistente de secuencia',
      iconSource: 'fas',
      icon: 'tasks',
      component: 'AssistantSequence',
      sidebarDefault: 'expanded',
      hidden: true,
    },
    {
      path: 'maintenance',
      text: 'Mantenimiento',
      iconSource: 'fas',
      icon: 'list-alt',
      component: 'Maintenance',

      hidden: true,
      permissions: ['Admin', 'Director', 'Mantenimiento'],
      sidebarDefault: 'collapsed',
    },
  ],
  dropdowns: [],
  sections: [
    {
      title: 'Monitoreo de Piso',
      directory: 'MonitoringSection',
      path: 'monitoring',
      dropdowns: [],
      routes: [
        {
          path: 'monitoring-process',
          text: 'Monitoreo de Piso',
          iconSource: 'fas',
          icon: 'desktop',
          component: 'Monitoring',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento'],
        },
        {
          path: 'lista-ordenes',
          text: 'Lista de Órdenes',
          iconSource: 'fas',
          icon: 'list',
          component: 'OrderList',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'Mantenimiento'],
        },
      ],
    },
    {
      title: 'Secuenciación Pistas y Jaulas',
      directory: 'TracksAndCages',
      path: 'tracks-and-cages',
      routes: [
        {
          path: 'delay-list-sequence',
          text: 'Atrasos',
          iconSource: 'fas',
          icon: 'calendar-week',
          component: 'DelayListSequence',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'timeline-sequence',
          text: 'Secuencia (LDT)',
          iconSource: 'fas',
          icon: 'calendar-alt',
          component: 'TimelineSequence',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'list-sequence',
          text: 'Secuencia (Listado)',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'ListSequence',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
      ],
      dropdowns: [],
    },
    {
      title: 'Secuenciación Semiejes y Tubos',
      directory: 'ShaftsAndTubes',
      path: 'shafts-and-tubes',
      routes: [
        {
          path: 'delay-list-sequence',
          text: 'Atrasos',
          iconSource: 'fas',
          icon: 'calendar-week',
          component: 'DelayListSequence',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
      ],
      dropdowns: [
        {
          path: 'timeline-sequence',
          text: 'Secuencia (LDT)',
          directory: 'TimelineSequence',
          iconSource: 'fas',
          icon: 'calendar-alt',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'cut',
              text: 'Corte',
              component: 'Cut',
            },
            {
              path: 'careado',
              text: 'Careado',
              component: 'Careado',
            },
            {
              path: 'machined',
              text: 'Maquinado',
              component: 'Machined',
            },
          ],
        },
        {
          path: 'list-sequence',
          text: 'Secuencia (Listado)',
          directory: 'ListSequence',
          iconSource: 'fas',
          icon: 'list-alt',
          permissions: ['Admin', 'Director', 'Supervisor'],
          routes: [
            {
              path: 'cut',
              text: 'Corte',
              component: 'Cut',
            },
            {
              path: 'careado',
              text: 'Careado',
              component: 'Careado',
            },
            {
              path: 'machined',
              text: 'Maquinado',
              component: 'Machined',
            },
          ],
        },
      ],
    },
    {
      title: 'Análisis de Cumplimiento',
      path: 'compliance-analysis',
      directory: 'ComplianceAnalysis',
      routes: [
        {
          path: 'production-requirements',
          text: 'Requerimientos de Producción',
          iconSource: 'fas',
          icon: 'list',
          component: 'ProductionRequirements',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
      ],
      dropdowns: [
        {
          path: 'fulfillment',
          text: 'Cumplimiento de Venta',
          iconSource: 'fas',
          icon: 'clipboard-check',
          directory: 'Fulfillment',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'tracks-and-cages',
              text: 'Pistas y Jaulas',
              iconSource: 'fas',
              component: 'TracksAndCages',
              sidebarDefault: 'collapsed',
            },
            {
              path: 'shafts-and-tubes',
              text: 'Semiejes y Tubos',
              iconSource: 'fas',
              component: 'ShaftsAndTubes',
              sidebarDefault: 'collapsed',
            },
          ],
        },
      ],
    },
    {
      title: 'MPS',
      path: 'mps',
      directory: 'mps',
      dropdowns: [
        {
          path: 'fulfillment',
          text: 'Cumplimiento de Venta',
          iconSource: 'fas',
          icon: 'clipboard-check',
          directory: 'Fulfillment',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'tracks-and-cages',
              text: 'Pistas y Jaulas',
              iconSource: 'fas',
              // icon: 'clipboard-check',
              component: 'TracksAndCages',
              sidebarDefault: 'collapsed',
            },
            {
              path: 'shafts-and-tubes',
              text: 'Semiejes y Tubos',
              iconSource: 'fas',
              // icon: 'clipboard-check',
              component: 'ShaftsAndTubes',
              sidebarDefault: 'collapsed',
            },
          ],
        },
        {
          path: 'sales',
          text: 'Órdenes de Venta',
          iconSource: 'fas',
          icon: 'money-check-alt',
          directory: 'SalesOrders',
          sidebarDefault: 'collapsed',
          routes: [
            {
              path: 'tracks-and-cages',
              text: 'Pistas y Jaulas',
              iconSource: 'fas',
              component: 'TracksAndCages',
              sidebarDefault: 'collapsed',
            },
            {
              path: 'shafts-and-tubes',
              text: 'Semiejes y Tubos',
              iconSource: 'fas',
              component: 'ShaftsAndTubes',
              sidebarDefault: 'collapsed',
            },
          ],
        },
      ],
      routes: [
        {
          path: 'summary',
          text: 'Resumen',
          iconSource: 'fas',
          icon: 'sigma',
          component: 'Summary',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'forecast',
          text: 'Forecast',
          iconSource: 'fas',
          icon: 'chart-line',
          component: 'Forecast',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'sales-requirements',
          text: 'Customer Requests',
          iconSource: 'fas',
          icon: 'list',
          component: 'SalesRequirements',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'production-requirements',
          text: 'Requerimientos de Producción',
          iconSource: 'fas',
          icon: 'list',
          component: 'ProductionRequirements',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        // TODO: add order accomplishment when read
      ],
    },
    {
      title: 'Registro de MP',
      directory: 'MaterialRegistrySection',
      dropdowns: [],
      routes: [
        {
          path: 'material-registry',
          text: 'Registro de MP',
          iconSource: 'fas',
          icon: 'inventory',
          sidebarDefault: 'collapsed',
          component: 'MaterialRegistry',
          hidden: false,
          permissions: ['Admin', 'Director', 'Planeación', 'Embarques/MP'],
        },
        {
          path: 'quality-material-registry',
          text: 'Inventario Materia Prima',
          iconSource: 'fas',
          icon: 'inventory',
          sidebarDefault: 'collapsed',
          component: 'QualityMaterialRegistry',
          hidden: false,
          permissions: ['Admin', 'Director', 'Planeación', 'Embarques/MP'],
        },
        {
          path: 'returned-material',
          text: 'Materia Prima Regresada',
          iconSource: 'fas',
          icon: 'recycle',
          sidebarDefault: 'collapsed',
          component: 'ReturnedMaterial',
          hidden: false,
          permissions: ['Admin', 'Director', 'Planeación', 'Embarques/MP'],
        },
      ],
    },
    {
      title: 'Operación',
      directory: 'OperationSection',
      path: 'operation',
      dropdowns: [],
      routes: [
        {
          path: 'crane',
          text: 'Gruista',
          iconSource: 'far',
          icon: 'forklift',
          component: 'Crane',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director', 'Gruista'],
        },
        {
          path: 'forklift',
          text: 'Montacarguista',
          iconSource: 'fas',
          icon: 'forklift',
          component: 'Forklift',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director', 'Montacarguista'],
        },
        {
          path: 'track-and-cages',
          text: 'Pistas y Jaulas',
          iconSource: 'fas',
          icon: 'analytics',
          component: 'TracksAndCagesScreen',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director'],
        },
        {
          path: 'cutting',
          text: 'Corte',
          iconSource: 'fas',
          icon: 'analytics',
          sidebarDefault: 'collapsed',
          component: 'CuttingScreen',
          hidden: false,
          permissions: ['Admin', 'Director', 'Operación', 'Supervisor', 'Corte'],
        },
        {
          path: 'careado',
          text: 'Careado',
          iconSource: 'fas',
          icon: 'analytics',
          sidebarDefault: 'collapsed',
          component: 'CareadoScreen',
          permissions: ['Admin', 'Director', 'Operación', 'Supervisor'],
        },
        {
          path: 'cnc',
          text: 'CNC',
          iconSource: 'fas',
          icon: 'analytics',
          sidebarDefault: 'collapsed',
          component: 'CncScreen',
          permissions: ['Admin', 'Director', 'Operación', 'Supervisor'],
        },
        {
          path: 'pesaje',
          text: 'Pesaje',
          iconSource: 'fas',
          icon: 'analytics',
          sidebarDefault: 'collapsed',
          component: 'Weighing',
          permissions: ['Admin', 'Director', 'Operación', 'Supervisor'],
        },
        {
          path: 'aduana',
          text: 'Aduana',
          iconSource: 'fas',
          icon: 'analytics',
          sidebarDefault: 'collapsed',
          component: 'AduanaScreen',
          permissions: ['Admin', 'Director', 'Operación', 'Supervisor'],
        },
        {
          path: 'shipments',
          text: 'Embarques',
          iconSource: 'fas',
          icon: 'analytics',
          sidebarDefault: 'collapsed',
          component: 'Shipments',
          permissions: ['Admin', 'Director', 'Operación', 'Supervisor'],
        },
        {
          path: 'contingency-mode',
          text: 'Modo Contingencia',
          iconSource: 'fas',
          icon: 'exclamation-triangle',
          sidebarDefault: 'collapsed',
          component: 'ContingencyMode',
          hidden: false,
          permissions: ['Admin'],
        },
      ],
    },
    {
      title: 'Reabastecimientos',
      path: 'replenishments',
      directory: 'Replenishments',
      routes: [],
      dropdowns: [
        {
          path: 'shafts-and-tubes',
          text: 'Semiejes y Tubos',
          directory: 'ShaftsAndTubes',
          iconSource: 'fas',
          icon: 'clipboard-check',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'Orders',
              text: 'Órdenes',
              component: 'Orders',
              permissions: 'all',
            },
            {
              path: 'requirements',
              text: 'Requerimientos',
              component: 'Requirements',
              permissions: 'all',
            },
            {
              path: 'analysis',
              text: 'Análisis',
              component: 'Analysis',
              permissions: 'all',
            },
          ],
        },
        {
          path: 'tracks-and-cages',
          text: 'Pistas y Jaulas',
          directory: 'TracksAndCages',
          iconSource: 'fas',
          icon: 'clipboard-check',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'Orders',
              text: 'Órdenes',
              component: 'Orders',
              permissions: 'all',
            },
            {
              path: 'requirements',
              text: 'Requerimientos',
              component: 'Requirements',
              permissions: 'all',
            },
            {
              path: 'analysis',
              text: 'Análisis',
              component: 'Analysis',
              permissions: 'all',
            },
          ],
        },
      ],
    },
    {
      title: 'Inventarios',
      directory: 'Inventory',
      path: 'inventory',
      routes: [
        {
          path: 'mp-forja',
          text: 'Materia Prima Forja',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'MPForja',
          hidden: true,
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'raw-material',
          text: 'Materia Prima',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'RawMaterials',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'mp-tubo',
          text: 'Materia Prima Tubo',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'MPTubo',
          sidebarDefault: 'expanded',
          hidden: true,
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'actual-raw-material-inventory',
          text: 'Materia Prima Actual',
          iconSource: 'fas',
          icon: 'boxes',
          component: 'ActualRawMaterialInventory',
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Supervisor'],
        },
        {
          path: 'tooling-inventory',
          text: 'Insumos',
          iconSource: 'fas',
          icon: 'inventory',
          component: 'ToolingInventory',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director', 'Insumos Herramentales'],
        },
      ],
      dropdowns: [],
    },
    // TODO: add recipes section when is ready
    {
      title: 'Manufacturas y Calidad',
      directory: 'QualitySection',
      path: 'quality',
      dropdowns: [],
      routes: [
        {
          path: 'sku-configuration',
          text: 'Configuración de SKU',
          iconSource: 'fas',
          icon: 'tools',
          component: 'SkuConfiguration',
          permissions: ['Admin', 'Director', 'Calidad', 'Manufactura', 'Corte'],
        },
        {
          path: 'puesta-a-punto-historic',
          text: 'Historial Puesta a Punto',
          iconSource: 'fas',
          icon: 'history',
          component: 'PuestaPuntoHistoric',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director', 'Supervisor', 'Calidad', 'Manufactura'],
        },
        {
          path: 'puesta-a-punto-validation',
          text: 'Verificar Puesta a Punto',
          iconSource: 'fas',
          icon: 'inventory',
          component: 'PuestaPuntoValidation',
          sidebarDefault: 'collapsed',
          hidden: false,
          permissions: ['Admin', 'Director', 'Supervisor', 'Calidad'],
        },
        {
          path: 'raw-material-validation',
          text: 'Verificar Materia Prima',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'RawMaterialValidation',
          permissions: ['Admin', 'Director', 'Supervisor', 'Calidad', 'Manufactura'],
        },
        {
          path: 'downtime-historic',
          text: 'Historial Paros',
          iconSource: 'fas',
          icon: 'history',
          component: 'DowntimeHistoric',
          permissions: ['Admin', 'Supervisor', 'Calidad', 'Manufactura'],
        },
        {
          path: 'quarantine',
          text: 'Cuarentena',
          iconSource: 'fas',
          icon: 'container-storage',
          component: 'Quarantine',
          permissions: ['Admin', 'Director', 'Supervisor', 'Calidad', 'Manufactura'],
        },
        // TODO: add Safe Launch when is ready
      ],
    },
    // TODO: add aduana section when is ready
    {
      title: 'Mantenimiento',
      directory: 'Maintenance',
      path: 'stops/',
      routes: [
        {
          path: 'import',
          text: 'Carga de Mantenimientos',
          iconSource: 'fas',
          icon: 'file-archive',
          component: 'Import',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'maintenance',
          text: 'Programa de Mantenimientos',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'Stops',
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'manage-reasons',
          component: 'ManageReasons',
          exactUrl: false,
          text: 'Administrar motivos paros',
          hidden: true,
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'schemas-viz',
          text: 'Viz WorkSchemas',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'WorkSchemasViz',
          hidden: true,
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'schemas',
          text: 'Esquemas de Trabajo',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'WorkSchemas',
          hidden: false,
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'shifts',
          text: 'Turnos',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'Shifts',
          hidden: false,
          sidebarDefault: 'expanded',
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'manage-reasons-shifts',
          component: 'ManageReasonsShifts',
          exactUrl: false,
          text: 'Administrar motivos turnos',
          hidden: true,
          permissions: ['Admin', 'Director', 'Supervisor', 'Mantenimiento', 'Planeación', 'SupplyChain'],
        },
        {
          path: 'maintenance-orders',
          text: 'Órdenes de Mantenimiento',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'MaintenanceOrders',
          hidden: false,
          sidebarDefault: 'collapsed',
          permissions: ['Admin', 'Director', 'Mantenimiento'],
        },
        {
          path: 'historical-maintenance',
          text: 'Historial de Mantenimiento',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'HistoricalMaintenance',
          sidebarDefault: 'collapsed',
          hidden: true,
          permissions: ['Admin', 'Director', 'Mantenimiento'],
        },
      ],
      dropdowns: [],
    },
    // TODO: add KPI section
    // TODO: add SPC section
    {
      path: 'setup',
      title: 'Configuración general',
      directory: 'Setup',
      routes: [
        {
          path: 'user-manual',
          text: 'Manual de uso',
          component: 'ManualScreen',
          permissions: 'all',
          iconSource: 'fas',
          icon: 'book',
        },
      ],
      dropdowns: [
        {
          directory: 'operation',
          text: 'Datos Operativos',
          path: 'operation',
          iconSource: 'fas',
          icon: 'industry-alt',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'part-number-barra',
              text: 'Números Parte Barra',
              component: 'PartNumberBarra',
              permissions: 'all',
              exactUrl: true,
            },
            {
              path: 'part-number-tubo',
              text: 'Números Parte Tubo',
              component: 'PartNumberTubo',
              permissions: 'all',
              exactUrl: true,
              hidden: false,
            },
            {
              path: 'part-number-forja',
              text: 'Números Parte Forja',
              component: 'PartNumberForja',
              permissions: 'all',
              exactUrl: true,
              hidden: false,
            },
            {
              path: 'resources',
              text: 'Máquinas',
              component: 'Resources',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'resource-materials',
              text: 'Materiales por Máquina',
              component: 'ResourceMaterials',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'process-resources',
              text: 'Máquinas por Proceso',
              component: 'ProcessResources',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'material-recipes',
              text: 'Recetas de Materiales',
              component: 'MaterialRecipes',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'recipes',
              text: 'Recetas',
              component: 'Recipes',
              exactUrl: false,
              hidden: true,
            },
            {
              path: 'customers',
              text: 'Clientes',
              component: 'Customers',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
        {
          directory: 'connectivity',
          text: 'Conectividad',
          path: 'connectivity',
          iconSource: 'fas',
          icon: 'network-wired',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'properties',
              text: 'Propiedades',
              component: 'PropertyCatalogues',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'units',
              text: 'Unidades',
              component: 'Units',
              permissions: 'all',
              exactUrl: false,
            },
          ],
        },
        {
          directory: 'properties',
          text: 'Propiedades',
          path: 'properties',
          iconSource: 'fas',
          icon: 'cog',
          permissions: ['Admin', 'Director', 'Supervisor', 'Planeación', 'SupplyChain'],
          routes: [
            {
              path: 'suppliers',
              text: 'Insumos de Proveedores',
              component: 'Suppliers',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
              icon: 'warehouse',
            },
            {
              path: 'supplies-per-supplier',
              text: 'Insumos por proveedor',
              component: 'SuppliesPerSupplier',
              permissions: 'all',
              exactUrl: false,
              hidden: true,
              icon: 'warehouse',
            },
            {
              path: 'supplies',
              text: 'Insumos',
              component: 'Supplies',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
              icon: 'warehouse',
            },
            {
              path: 'raw-materials',
              text: 'Materias primas',
              component: 'RawMaterials',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
              icon: 'warehouse',
            },
            {
              path: 'part-numbers',
              text: 'Números de parte',
              component: 'PartNumbers',
              permissions: 'all',
              exactUrl: false,
              icon: 'list',
            },
            {
              path: 'part-numbers-per-customer',
              text: 'Clientes por No. Parte',
              component: 'PartNumbersPerCustomer',
              permissions: 'all',
              exactUrl: false,
              icon: 'list',
            },
            // {
            //   path: 'customers',
            //   text: 'Clientes',
            //   component: 'Customers',
            //   permissions: 'all',
            //   exactUrl: false,
            //   hidden: false,
            //   icon: 'warehouse',
            // },
          ],
        },
      ],
    },
    {
      path: 'admin',
      title: 'Administración',
      directory: 'admin',
      routes: [
        {
          path: 'operation-requirements',
          text: 'Req. de Operación',
          iconSource: 'fas',
          icon: 'user',
          component: 'OperationRequirements',
          permissions: ['Admin'],
        },
      ],
      dropdowns: [
        {
          text: 'Permisos',
          directory: 'permissions',
          path: 'permissions',
          icon: 'key',
          iconSource: 'fas',
          routes: [
            {
              path: 'users',
              text: 'Users',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              hidden: false,
            },
            {
              path: 'groups',
              text: 'Groups',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: ['Admin'],
              hidden: false,
            },
            {
              path: 'tenancy-admin',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'TenancyAdmin',
              permissions: ['Admin'],
              hidden: false,
            },
            {
              path: 'alerts',
              text: 'Alerts',
              iconSource: 'fas',
              icon: 'sensor-alert',
              component: 'AlertsManagementScreen',
              permissions: ['Admin'],
              hidden: false,
            },
            {
              path: 'alerts-config',
              text: 'Configuración de Alertas',
              iconSource: 'fas',
              icon: 'sensor-alert',
              component: 'Alerts',
              permissions: ['Admin'],
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
